import React from "react"
import { graphql, Link } from "gatsby"
import { SiteMetadata } from "../components"
import { WavesSVG, ZigZag } from '../components/SVG/'
import { Layout } from "../layouts/Layout"
import Img from "gatsby-image"

import {Container, Row, Col} from "react-bootstrap"
import { FaChevronRight } from "react-icons/fa"

export default ({ data }) => {
  return (
    <Layout noWaveHeader>
        <SiteMetadata 
            title="О нас" 
            canonical="/about/"
            description="150 пчелиных ульев, опытный экскурсовод-пчеловод, большой природный ингалятор (апидомик) и дегустация продуктов пчеловодства у самовара с чаем и полезной беседой." 
        />
        <Container fluid className="blue">
            <Container>
                <Row>
                    <Col sm={12} className="text-center">
                        <h1 className="d-inline-block mb-4">
                            Что такое Пчелиный Хуторок?
                            <ZigZag/>
                        </h1>
                        
                    </Col>
                    <Col lg={8} className="offset-lg-2 text-center">
                        <p>
                            Это светлые и искренние отношения между пчелами и людьми.<br/>
                            Это место, где можно погрузиться в сладкий мир и вернуться оттуда заряженным на Великие дела!
                        </p>
                        <p>
                            150 пчелиных ульев, опытный экскурсовод-пчеловод, большиой природный ингалятор (апидомик), дегустация продуктов пчеловодства у самовара с чаем и полезной беседой.
                            Мастер-классы по свечам и натуральному шоколаду, прогулка по сенсорной тропе.
                        </p>
                        <p>
                            Мы знаем, что вам уже захотелось к нам на Пчелиный Хуторок!<br/>Познакомимся?
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
        <WavesSVG down />
        <Container>
            <Row className="justify-content-center ">
                <Col xs={10} lg={5} className="align-self-center my-auto order-lg-last">
                    <Img fluid={data.boss.childImageSharp.fluid} alt="пчеловод-экскурсовод"/>
                </Col>
                <Col sm={12} lg={7} className="text-center text-lg-left">
                    <h3 className="my-4">Нестеров Александр Анатольевич</h3>
                    <p>
                        Человек широкой души и улыбки! 
                        Сколько там опыта и мудрости- знает только Вселенная! 
                        Пчеловодство случилось с Александром Анатольевичем в далёком 1985 году. 
                        А вот что он рассказывает:
                    </p>
                    <p className="font-italic mt-3">
                        "Пчёл я завёл в 1985 году прошлого столетия. 
                        Две семьи купил у знакомого пчеловода и два роя заселились в заряженные мною ульи. 
                        В тот период было мне 28 лет и уже имел 2 сыновей.
                        Мой дед, Иван Фёдорович, до ВОВ водил пчёл, но с войны вернулся инвалидом Iгр и уже пчёл содержать не мог. 
                        На мои просьбы помочь отвечал:
                    </p>
                    <p className="font-italic">
                        - Первый мёд не продавай и себе не бери, а раздай дарма, если хочешь, чтобы у тебя пчёлы велись, из гнезда мёд не бери - это на зиму пчёлам.
                    </p>
                    <p className="font-italic">
                        В первый сезон я откачал 3 ведра, дал всем по баночке в деревне, привёз 3х литровую банку деду, а он спрашивает...
                    </p>
                    <Link className="btn btn-primary rounded px-3 my-3" to="/blog/start/">Читать далее <FaChevronRight className="icon-svg"/></Link>
                </Col>                
            </Row>
        </Container>

        <WavesSVG up/>
        <Container fluid className="blue position-relative">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={10} md={6} className="justify-content-center my-auto">
                        <Img fluid={data.nastyantolik.childImageSharp.fluid} alt="Анастасия и Анатолий Нестеровы"/>
                    </Col>
                </Row>
            </Container>            
            <WavesSVG up white className="position-absolute w-100 l-0 r-0 b-0"/>
        </Container>

        <Container>
            <Row>
                <Col sm className="text-center mt-5">
                    <div>
                        <h3 className="mb-3">Нестерова Анастасия Константиновна</h3>
                        <p>
                            Жена младшего сына Анатолия. 
                            Судьба-злодейка и свела этих двух людей в колледже, где они вместе учились на пчеловодов! 
                            Только в отличии от Толи, карьера Анастасии закончилась, так и не начавшись))
                        </p>
                        <p>
                            Свой потенциал она реализовывает в творчестве: производство натурального шоколада на меду, свеч, косметики, упаковка подарочных наборов.
                            Так же общается с гостями сайта и Пчелиного Хуторка, проводит мастер-классы, организовывает экскурсии.
                        </p>
                    </div>                    
                </Col>
                <Col className="text-center mt-5">
                    <div>
                        <h3 className="mb-3">Нестеров Анатолий Александрович</h3>
                        <p>
                            Младший сын, которому привили любовь к пчелам уже с пелёнок.
                            Свои знания подкрепил дипломом по специальности "Пчеловодство" 
                        </p>
                        <p>
                            В свободное время продолжает обучаться и реализовывать разные направления этой сферы - породность пчёл, различные способы содержания, матководство.
                            Посещает семинары и форумы, обучает и консультирует начинающих пчеловодов, участвует в фестивалях и ярмарках. Любит своё ремесло, которое отвечает взаимностью.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
        
        
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    boss: file(relativePath: {eq: "images/boss.png"}){
      childImageSharp {
        fluid(maxWidth: 445) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nastyantolik: file(relativePath: {eq: "images/nastyantolik.png"}){
        childImageSharp {
            fluid(maxWidth: 540) {
            ...GatsbyImageSharpFluid
            }
        }
    }
  }
`